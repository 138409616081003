import React from 'react';

const LocationHeader = ({ location }) => {
  return (
    <div className="tw-grid tw-gap-1 tw-overflow-y-auto md:tw-max-h-full tw-border-b-2 tw-border ">
      <h2 className="tw-font-bold tw-tracking-wide tw-text-lg md:tw-text-xl tw-text-blue tw-m-0">
        {location.Site_Description}
      </h2>
      <p className="tw-font-medium tw-text-base tw-m-0 tw-grid">
        <span>{location.Address_1} </span>
        <span>
          {location.City}, {location.Province}, {location.Postal_Code}
        </span>
      </p>

      {location.distanceFromInKms && (
        <p className="tw-text-base tw-text-blue tw-m-0">
          {location.distanceFromInKms} kms away
        </p>
      )}
      <p className="tw-text-base tw-tracking-normal tw-text-blue tw-m-0">
        {location.Phone}
      </p>
    </div>
  );
};

export default LocationHeader;
